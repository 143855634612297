export enum Colors {
	Pink = '#FF197A',
	Black = '#000',
}

export enum Rotation {
	Up_Left = '0deg',
	Up_Right = '90deg',
	Down_Left = '270deg',
	Down_Right = '180deg',
}
